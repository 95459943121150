import Services from "../Services/Services";
import Specialisms from "../Specialisms/Specialisms";

function ServicesAndSpecialisms() {
  return (
    <section>
      <Services />
      <Specialisms />
    </section>
  );
}

export default ServicesAndSpecialisms;
